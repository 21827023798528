// import * as React from 'react';
import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// StringForm
import StringForm from './StringForm';

const stringCreate = () => {
    const [alertError, setAlertError] = useState({});

    const handleAlertError = (message) => {
        setAlertError(message);
    }

    return (

        <Container component="div" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Create String
                </Typography>

                <>
                    {
                        Object.keys(alertError).length !== 0 ?
                            <Alert 
                            severity="error"
                            sx={{m: 3}}
                            >
                                {alertError}
                            </Alert>
                        : null
                    }
                </>

                <StringForm string={null} handleError={handleAlertError} />

            </Box>

        </Container>
    );
}

export default stringCreate;