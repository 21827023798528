import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`loginregister-tabpanel-${index}`}
      aria-labelledby={`loginregister-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `loginregister-tab-${index}`,
    'aria-controls': `loginregister-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [alertMessage, setAlertMessage] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box 
      sx={{ 
        width: {
          xs: "90%",
          sm: "80%",
        },
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        pt: 2,
        pb: 2,
        boxShadow: '0px 25px 80px rgba(4, 73, 144, 0.52)',
      }}
    >
      <Box 
        sx={{ 
          display: `flex`,
          justifyContent: `center`,
          m: 0,
          p: 0,
          pb: 3
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Accedi" {...a11yProps(0)} sx={styles.tabStyles} />
          <Tab label="Registrati" {...a11yProps(1)} sx={styles.tabStyles} />
        </Tabs>
      </Box>
      {alertMessage && <Alert severity="success" sx={{mt: 2, mr: 5, mb: 1, ml: 5}}>{alertMessage}</Alert>}
      <TabPanel value={value} index={0}>
        <LoginForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RegisterForm />
      </TabPanel>
    </Box>
  );
}

const styles = {
  tabStyles: {
    textTransform: 'none',
    fontWeight: '500',
    pb: 0,
    color: '#004990',
    borderColor: '#004990',
    border: '4px',
  }
}