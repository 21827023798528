import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Footer from '../../components/Footer';
import ContactButton from '../../components/ContactButton';
import CookieBar from '../../components/CookieBar';

export default function Dashboard() {
    const { user } = useAuth();

    return (
      <Box 
        sx={{
          backgroundColor: '#F4F7F7',
          width: '100%',
          padding: '0px',
        }} 
      >
        <Outlet />
        <ContactButton />
        <CookieBar />
        <Footer />
      </Box>
    );
  }