import { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { Typography } from "@material-ui/core";
// utils
import axios from "../../utils/axios";

import { Link } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditDeleteBox(props) {
    const [itemId, setItemId] = useState(null);
    const [items , setItems] = useState('');

    useEffect(() => {
        if (props.itemId) {
            setItemId(props.itemId);
        }
        if (props.items) {
            setItems(props.items);
        }
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Link href={`/admin/${items}/update/${itemId}`} underline="none" color="inherit" 
                sx={{
                    marginRight: '5px',
                    display: "flex",
                    alignItems: "center",
                }}>
                <EditIcon />
            </Link>
            <DeleteIcon sx={{ cursor: 'pointer', marginLeft: '5px' }} onClick={props.handleDelete} />
        </Box>

        
    );
}
