import { Box } from "@mui/system";
import { Button } from "@mui/material";

import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function ContactButton() {
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Box
            sx={{
                display: {
                    xs: "flex",
                    sm: "none",
                },
                position: "fixed",
                bottom: "25px",
                right: "25px",
            }}
        >
            <Button 
                color="inherit"
                sx={{
                    borderRadius: "50px",
                    backgroundColor: "#004990",
                    color: 'white',
                    padding: "10px 20px",
                }}
                onClick={() => { openInNewTab("https://hellougo.typeform.com/to/xAz5zXUI") }}
            >
                <MailOutlineIcon /> 
            </Button>
        </Box>
    );
}
