import { Box } from "@mui/system";
import { Typography } from "@material-ui/core";
// components
import PageHeader from "../../components/PageHeader";

export default function InformativaPrivacy() {
  return (
    <Box
      sx={{
        padding: {
          xs: "20px",
          sm: "35px",
          md: "54px",
        },
        paddingBottom: {
          xs: "100px !important",
          md: "150px !important",
        },
      }}
    >
      <PageHeader title="Informativa privacy" />
      <Box style={styles.boxParagraph}>
        <Typography style={styles.bold}>
          Informativa ai sensi dell’art. 13 del Regolamento Europeo 679/2016
          GDPR
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          1) Titolare del trattamento e responsabile della protezione dei dati
          personali.
        </Typography>
        <Typography>
          Titolare del trattamento è The Bloc Italy S.r.l. SB, in persona del
          proprio legale rappresentante pro tempore, con sede legale in Milano,
          Via Tommaso Agudio, 2 – 20154 C.F, P.IVA e Reg. Imp. Milano
          07194560962 – REA MI-1942230.
        </Typography>
        <Typography>
          Il Titolare può essere contattato mediante e-mail all’indirizzo
          italy.info@thebloc.com
        </Typography>
        <Typography>
          Il titolare ha nominato un responsabile della protezione dei dati
          personali (DPO) contattabile all’indirizzo mail dpo@thebloc.com
        </Typography>
        <Typography>
          Il trattamento dati, come di seguito descritto, avviene in Milano, Via
          Tommaso Agudio, 2 tramite sistema cloud completamente automatizzato.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          2) Tipologia di dati e di trattamento.
        </Typography>
        <Typography>
          I dati trattati sono comuni e consistono nel solo indirizzo e-mail
          necessario per l’accesso ai servizi da Lei richiesti e ci saranno
          forniti esclusivamente da Lei, in qualità di soggetto interessato,
          mediante inserimento degli stessi sul portale www.caretogether.com. La
          semplice navigazione sul sito non richiede creazione di account. Il
          sito internet, nel corso del suo funzionamento, potrà anche acquisire
          alcuni dati la cui trasmissione è automatica nel corso della
          navigazione quali, ad esempio, indirizzo IP, identificativi on line,
          orario del contatto, ma saranno utilizzati solo per fornire
          statistiche anonime sull’uso del sito stesso e controllarne eventuali
          anomalie, così come evitare frodi; a quest’ultimo scopo, i dati
          potranno essere utilizzati ai soli fini di eventuali comunicazioni
          alle competenti Autorità ai fini dell’accertamento di responsabilità.
        </Typography>
        <Typography>
          In linea generale, tutte le tipologie di trattamento potranno essere
          individuate in quelle previste dall’art.4 comma 1, n° 2 del Reg.UE.
          679/16 ma si limiteranno, di regola, alla raccolta, registrazione,
          organizzazione e conservazione per il periodo strettamente necessario.
        </Typography>
        <Typography>
          I Suoi dati saranno, comunque, trattati in modo lecito, corretto e
          trasparente. Saranno trattati solo i dati necessari ed essenziali per
          il raggiungimento della finalità specifica (c.d. minimizzazione del
          trattamento e responsabilizzazione ex art 5 comma 1 lett. c del GDPR),
          con garanzia di esattezza e integrità del dato.
        </Typography>
        <Typography>
          Le persone di età inferiore ai 14 anni potranno validamente
          autorizzare il trattamento dei propri dati ma non usufruire dei
          servizi senza previo consenso dei genitori o, comunque, del titolare
          della responsabilità genitoriale ai sensi dell’art.8 del GDPR.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          3) Finalità e modalità del trattamento dei dati.
        </Typography>
        <Typography>
          Il trattamento è principalmente finalizzato alla corretta e completa
          erogazione dei servizi richiesti. In particolare il dato
          dell’indirizzo mail sarà utilizzato per la sola finalità permettere ed
          ottenere accesso ai servizi ed alla documentazione richiesti. I dati
          saranno trattati con strumenti elettronici solo in presenza di una
          base giuridica. I dati potranno essere trattati tramite dispositivi
          informatici c.d. in cloud e risiedere in archivi di quest’ultimo
          genere; in questo momento il cloud provider del titolare è Aruba,
          anche per i servizi di posta elettronica, sito all’interno dello SEE,
          come desumibile dalla privacy policy di detto fornitore, reperibile
          sul relativo sito.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          4) Base giuridica del trattamento.
        </Typography>
        <Typography>
          Il titolare tratta i dati personali in modo lecito, corretto e
          trasparente e la base giuridica del loro trattamento sarà, come sopra
          indicato per ciascuna finalità, la fornitura del servizio richiesto ex
          art.6 comma 1 lett.b del Reg EU 679/16.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          5) Condivisione dei dati.
        </Typography>
        <Typography>
          I dati personali non saranno condivisi né saranno trattati ad di fuori
          dello SEU.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          6) Conseguenze della mancata comunicazione ed aggiornamento dei dati
          personali.
        </Typography>
        <Typography>
          Con riguardo ai dati personali necessari alla fornitura del servizio
          richiesto, la mancata comunicazione o aggiornamento degli stessi ne
          impedisce la fornitura medesima, rendendo, di fatto, impossibile
          l’accesso alla piattaforma e quindi al servizio richiesto.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          7) Conservazione dei dati.
        </Typography>
        <Typography>
          I dati personali, oggetto di trattamento per le finalità sopra
          indicate saranno conservati, ai sensi dell’art.13.2°comma lett. a)
          GDPR, per tempo strettamente necessario. Detta durata viene, sin
          d’ora, fissata in cinque anni. Seguirà cancellazione.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          8) Profilazione e Diffusione dei dati.
        </Typography>
        <Typography>
          I dati personali non sono soggetti a diffusione né ad alcun processo
          decisionale interamente automatizzato, ivi compresa la profilazione,
          da parte del titolare. I dati non verranno utilizzati per l’invio di
          comunicazioni commerciali ed in generale per attività di marketing.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={{ ...styles.italic, ...styles.bold }}>
          9) Sicurezza dei dati.
        </Typography>
        <Typography>
          Il titolare s’impegna a proteggere i dati da accessi non autorizzati o
          da altre alterazioni. Ciò implica l'utilizzo di diverse misure di
          sicurezza (password, firewall, antivirus, backup) al fine di
          proteggere dati conservati oltre che continue revisioni delle modalità
          di raccolta, conservazione e trattamento dei dati.
        </Typography>
        <Typography>
          In conformità con quanto previsto alla presente informativa, il
          titolare tratterà tutti i dati personali in maniera strettamente
          confidenziale, in modo da preservarne integrità, riservatezza e
          disponibilità (art.32 GDPR) ed intraprenderà ogni ragionevole azione
          al fine di garantire la sicurezza dei dati, una volta in possesso del
          titolare. Allo stesso modo, il titolare imporrà a terzi fornitori
          analoghe misure. Nonostante ciò, internet non è un mezzo sicuro, e non
          si può garantire la sicurezza di tutti i dati. Pertanto, Lei accetta i
          rischi nel fornire tali informazioni e non potrà ritenere responsabile
          il titolare per nessuna violazione dei dati, a meno che ciò non sia
          dovuto ad una mancanza relativa ed imputabile a comportamento od
          omissione del titolare medesimo nella realizzazione ed implementazione
          delle adeguate misure tecniche ed organizzative, come richiesto dal
          GDPR.
        </Typography>
      </Box>
    </Box>
  );
}

const styles = {
  boxParagraph: {
    marginBottom: "20px",
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
};
