import { useState } from "react";

import { Box } from '@mui/system';
import { Alert } from '@mui/material';

import StringBox from './StringBox';

export default function StringList(props) {
    const classes = { paper: 'paper', root: 'root fullscreen' };
    const [alertMessage, setAlertMessage] = useState('');

    const handleAlertMessage = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage('');
        } , 3000);
    }

    return (
        <Box
            sx={{}}
        >
            {alertMessage && <Alert severity="success">{alertMessage}</Alert>}

            <StringBox items={50} showAlert={handleAlertMessage}></StringBox>
        </Box>
    );
} 