import { Box,  } from "@mui/system";
import { Link } from "@mui/material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

export default function PreFooter(props) {
    const classes = { paper: 'paper', root: 'root fullscreen' };

    return (
        <>
            <Grid container spacing={0}
                className={classes.root} 
                direction="row"
                margin="0 auto"
                alignItems="center"
                maxWidth="xs"
                sx={{
                    marginTop: "30px",
                    paddingTop: "30px",
                    backgroundColor: props.backgroundColor ? props.backgroundColor : '#494A4C',
                }}
            >
                <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center' }}>
                    <Typography variant="noSpaceParagraph" style={styles.footerInformativa} sx={{ "& a": { color: "white" } }} >
                        {
                            props.content
                            ?   ReactHtmlParser(
                                    SlateSerializer({ children: JSON.parse((props.content)) })
                                )
                            : ''
                        }
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

const styles = {
    footerInformativa: {
        display: "inline-block",
        padding: "0 54px",
        color: "white",
        fontSize: '12px',
    }
};