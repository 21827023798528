// import * as React from 'react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
// Formik
import { useFormik } from 'formik';

import { Input, Typography } from '@material-ui/core';
import { Button, Icon, Toolbar, Alert, TextField, FormHelperText } from '@mui/material';
// Hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// Utils
import axiosInstance from '../../../utils/axios';
// slate editor
import SlateEditor from '../../SlateEditor';

import { withReact } from 'slate-react'
import {
  Editor,
  createEditor
} from 'slate'
import { withHistory } from 'slate-history'
import { CollectionsBookmarkRounded } from '@material-ui/icons';

const stringForm = (props) => {
  const [string, setString] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState('');
  // slate editor
  const [slateText, setStateText] = useState('');
  const [content, setContent] = useState('');
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const isMountedRef = useIsMountedRef();

  const handleError = (error) => {
    props.handleError(JSON.stringify(error));
  }

  const handleEditorChange = (value) => {
    setStateText(value);
  }

  useEffect(() => {
    if (props.string) {
      setString(props.string);
      setIsEdit(true);
      setContent(props.string.content);
    } else {
      setIsEdit(false);
    }
  }, [props.string]);

  const CreateSchema = Yup.object().shape({
    // content: Yup.string()
    //   .required('Content required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      key: string.key ? string.key : '',
      content: string.content ? string.content : '',
    },
    validationSchema: CreateSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const editorText = JSON.parse(slateText);
        let editorEmpty = true;

        // Check if editor is empty
        for (let node of editorText) {
          if (!Editor.isEmpty(editor, node)) {
            editorEmpty = false;
            break;
          }
        }

        if (editorEmpty) {
          handleError('Content is empty');
          throw new Error('Content is required');
        }

        const data = new FormData() 
        data.append('key', values.key);
        data.append('content', slateText); // values.content

        let url = ``;

        if (isEdit) {
          url = `/strings/update/${string.id}`;
        } else {
          url = `/strings/create`;
        }

        await axiosInstance.post(url, data)
          .then(response => {
            setString(response.data.string);
            setAlertSuccess(`String has been ${isEdit ? 'updated' : 'created'}`);
          })
          .catch(error => {
            console.error(error.data);
            handleError(error.data);
          });
        
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.data });
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.data });

          if (error.status !== 401) {
            resetForm();
          }

          if (error.status === 401) {
            setErrors({ afterSubmit: translate('auth.login.username_not_validated') });
          }

          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

      {alertSuccess && <Alert severity="success">{alertSuccess}</Alert>}
      
      <TextField
          id="key"
          name="key"
          autoComplete="key"
          disabled={isEdit}
          onChange={formik.handleChange}
          value={formik.values.key}
          error={formik.touched.key && Boolean(formik.errors.key)}
          helperText={formik.touched.key && formik.errors.key}
          placeholder='Key'

          margin="normal"
          fullWidth
          autoFocus
      />

      <SlateEditor initialValue={content} handleChange={handleEditorChange} placeholder='Content' />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {
          isEdit 
            ? 'Update' 
            : 'Create'
        }
      </Button>

    </form>
  );
}

export default stringForm;