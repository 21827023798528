import { useState, useEffect } from "react";

import { Box } from "@mui/system";
import { Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";
// paths
import { PATH_PRIVACY } from "../routes/paths";

export default function Footer() {
  const classes = { paper: "paper", root: "root fullscreen" };

  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ["footer_info"];

  const getStrings = async () => {
    try {
      const response = await axios.post("/strings/list/subset", {
        keys: stringKeys,
      });
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStrings();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.root}
        direction="row"
        margin="0 auto"
        alignItems="center"
        maxWidth="xs"
        sx={{
          padding: "30px 0",
          backgroundColor: "#494A4C",
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}
        >
          <Box>
            <Box
              component="img"
              sx={{
                maxWidth: "200px",
              }}
              src="/static/images/logo_tnw-white.png"
              alt="logo-thenw"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "20px 0" }}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem color="#fff" />}
            spacing={2}
            sx={{
              justifyContent: "center",
            }}
          >
            <Link href={PATH_PRIVACY.main.informativa} color="#fff">
              <Typography variant="body1" style={styles.footerLink}>
                Informativa privacy
              </Typography>
            </Link>

            {/* <Link href={PATH_PRIVACY.main.terminiUso} color='#fff'>
                            <Typography variant="body1" style={styles.footerLink} >
                                Termini d'uso
                            </Typography>
                        </Link> */}

            <Link href={PATH_PRIVACY.main.cookies} color="#fff">
              <Typography variant="body1" style={styles.footerLink}>
                Cookies
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <Box
              component="img"
              sx={{
                maxWidth: "200px",
              }}
              src="/static/images/logo_gilead-white.png"
              alt="logo-gilead"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const styles = {
  footerLink: {
    color: "white",
    fontSize: "12px",
  },
  footerInformativa: {
    display: "inline-block",
    padding: "0 54px",
    color: "#494A4C",
    fontSize: "12px",
  },
};
