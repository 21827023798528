import Grid from "@material-ui/core/Grid";
import { Link } from "@mui/material";

import { Typography } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/system";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import Footer from "../components/Footer";
// paths
import { PATH_PRIVACY } from "../routes/paths";
import CookieBar from "../components/CookieBar";

export default function OnlyFormLayout(props) {
  const classes = { paper: "paper", root: "root fullscreen" };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: {
          xs: "url(/static/images/no-person-bg.png)",
          md: "url(/static/images/person-bg.png)",
        },
        backgroundSize: "cover",
        backgroundPosition: "center left",
      }}
    >
      <Grid
        container
        spacing={0}
        className={classes.root}
        direction="row"
        margin="0 auto"
        alignItems="center"
        maxWidth="lg"
        sx={{
          position: "relative",
          minHeight: "100vh",
          paddingTop: {
            xs: "50px",
            md: "100px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              // md: "inherit"
            },
            padding: {
              xs: "50px 0",
              md: "0",
            },
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: {
                xs: "70%",
                md: "100%",
              },
            }}
            src="/static/images/logo-cart.svg"
            alt="logo-car-t"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Outlet />
        </Grid>
        <Grid container item xs={12}></Grid>{" "}
        {/* Need to keep a from the elements above */}
        <Grid
          container
          item
          xs={12}
          sx={{
            padding: "30px 0",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                // md: "inherit"
              },
              padding: {
                xs: "50px 0 20px 0",
                md: "0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                width: {
                  xs: "70%",
                  md: "475px",
                },
                paddingBottom: {
                  xs: "20px",
                  md: "0px",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  maxWidth: {
                    xs: "120px",
                    sm: "150px",
                    md: "200px",
                  },
                }}
                src="/static/images/logo_tnw.png"
                alt="logo-thenw"
              />
              <Box
                component="img"
                sx={{
                  maxWidth: {
                    xs: "120px",
                    sm: "150px",
                    md: "200px",
                  },
                }}
                src="/static/images/logo_gilead.png"
                alt="logo-gilead"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: {
                xs: "50px",
                md: "0",
              },
            }}
          >
            <Stack
              direction="row"
              divider={
                <Divider orientation="vertical" flexItem color="#004990" />
              }
              spacing={2}
              sx={{
                justifyContent: "center",
              }}
            >
              <Link href={PATH_PRIVACY.main.informativa} color="#000000">
                <Typography variant="body1" style={styles.footerLink}>
                  Informativa privacy
                </Typography>
              </Link>

              {/* <Link href={PATH_PRIVACY.main.terminiUso} color='#000000'>
                                <Typography variant="body1" style={styles.footerLink} >
                                    Termini d'uso
                                </Typography>
                            </Link> */}

              <Link href={PATH_PRIVACY.main.cookies} color="#231F20">
                <Typography variant="body1" style={styles.footerLink}>
                  Cookies
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <CookieBar />
    </Box>
  );
}

const styles = {
  footerLink: {
    color: "#231F20",
    fontSize: "12px",
  },
};
