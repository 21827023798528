import { useState, useEffect } from "react";

import { Box } from "@mui/system";
// components
import HomeBanner from "../components/HomeBanner";
import CenteredLaunch from "../components/CenteredLaunch";
import RoundedBox from "../components/RoundedBox";
import SectionIntro from "../components/SectionIntro";
import ServicesBoxes from "../components/ServicesBoxes";
import FrontendAppMenu from "../components/FrontendAppMenu";
import PreFooter from "../components/PreFooter";
// hooks
import useAuth from "../hooks/useAuth";
// utils
import axios from "../utils/axios";
// paths
import { PATH_MAIN } from '../routes/paths';
import AssistanceAlert from "../components/AssistanceAlert";

export default function HomeLayout(props) {
  const { isAuthenticated, expireAccessDate, role, logout } = useAuth();
  const classes = { paper: "paper", root: "root fullscreen" };
  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ['home_banner_subtitle', 'centered_launch_text', 'roundedbox_homepage_title', 'roundedbox_homepage_description', 'servizi_supporto_intro', 'prefooter_homepage_content', 'home_assistante_title', 'home_assistante_content'];

  const getStrings = async () => {
    try {
      const response = await axios.post('/strings/list/subset', { keys: stringKeys });
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStrings();
  }, []);

  return (
    <>
      <Box>
        <FrontendAppMenu />
        {isAuthenticated && 
          <AssistanceAlert 
            title={strings['home_assistante_title']}
            content={strings['home_assistante_content']}
            image="/static/images/assistance_icon.png"
          />
        }
        <HomeBanner
          description={strings['home_banner_subtitle']}
        />
        <CenteredLaunch
          description={strings['centered_launch_text']}
        />
        <RoundedBox
          title={strings['roundedbox_homepage_title']}
          description={strings['roundedbox_homepage_description']}
          link={PATH_MAIN.main.materialiInformativi}
        />
        <SectionIntro
          title="Servizi di supporto"
          description={strings['servizi_supporto_intro']}
        />
        <ServicesBoxes items={3}></ServicesBoxes>
      </Box>
      <PreFooter 
        content={strings['prefooter_homepage_content']}
      />
    </>
  );
}
