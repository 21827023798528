import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_MAIN, PATH_ADMIN } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, role } = useAuth();

  if (isAuthenticated) { 
    

    if (role === 'USER') {
      return <Navigate to={PATH_MAIN.main.dashboard} />;
    }

    return <Navigate to={PATH_ADMIN.admin.dashboard} />;
  }

  return <>{children}</>;
}
