import { useState, useEffect } from "react";

import { Box } from "@mui/system";
// components
import PageHeader from "../components/PageHeader";
import HomeBanner from "../components/HomeBanner";
import CenteredLaunch from "../components/CenteredLaunch";
import RoundedBox from "../components/RoundedBox";
import SectionIntro from "../components/SectionIntro";
import ServicesBoxes from "../components/ServicesBoxes";
import FrontendAppMenu from "../components/FrontendAppMenu";
import PreFooter from "../components/PreFooter";
// utils
import axios from "../utils/axios";

export default function ServicesLayout(props) {
  const classes = { paper: "paper", root: "root fullscreen" };
  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ['home_banner_subtitle', 'centered_launch_text', 'rounded_box_title', 'rounded_box_description', 'servizi_supporto_intro', 'servizi_supporto_single_subtitle', 'prefooter_servizi_supporto_content'];

  const getStrings = async () => {
    try {
      const response = await axios.post('/strings/list/subset', { keys: stringKeys }); 
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStrings();
  }, []);

  return (
    <>
      <Box>
        <FrontendAppMenu />
        <PageHeader 
          title="Servizi di supporto"
          subtitle={strings['servizi_supporto_single_subtitle']}
        />
        <ServicesBoxes 
          loadMore={false} 
          style={{
            backgroundImage: "url(/static/images/services_bg.png), linear-gradient(rgba(44, 164, 222, .1), rgba(44, 164, 222, .0))",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 350px",
          }}></ServicesBoxes>
      </Box>
      <PreFooter content={strings['prefooter_servizi_supporto_content']} />
    </>
  );
}
