import { useState, useEffect } from "react";

import { Box } from "@mui/system";
// import  from "@material-ui/core/Grid";
import { Container, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
// components
import PageHeader from "../components/PageHeader";
import FrontendAppMenu from "../components/FrontendAppMenu";
import PreFooter from "../components/PreFooter";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

const boxes = [
  {id: "first", imagePath: "/static/images/materiali_informativi/car_t.png", title: "Le cellule CAR-T", content: "<strong>Le cellule CAR-T</strong> sono linfociti T propri del paziente, geneticamente modificati per colpire una molecola specifica del tumore<sup>1</sup>"},
  {id: "second", imagePath: "/static/images/materiali_informativi/linfociti.png", title: "I linfociti T", content: "<strong>I linfociti T</strong> hanno un ruolo chiave nella difesa del nostro organismo<sup>2</sup>"},
  {id: "third", imagePath: "/static/images/materiali_informativi/recettore_car.png", title: "Il recettore CAR", content: "<strong>Il recettore CAR</strong> è una molecola, presente sul linfocita T, capace di riconoscere le cellule tumorali<sup>3</sup>"},
];

const paths = [
  {id: 11, imagePath: "/static/images/materiali_informativi/percorso_1.png", title: "1. Primo appuntamento", description: "La storia clinica del paziente è valutata dal medico per confermare la sua candidabilità alla terapia con CAR-T.<sup>1</sup>"},
  {id: 2, imagePath: "/static/images/materiali_informativi/percorso_2.png", title: "2. Aferesi", description: "Il paziente è sottoposto ad uno speciale prelievo di sangue in cui vengono raccolti i linfociti T.<sup>1</sup> <br> Il materiale raccolto viene spedito a centri specializzati dove avverrà l’ingegnerizzazione1<sup>1</sup> <br><br>  <strong>Il processo di aferesi richiede circa 3-4 ore<sup>1</sup></strong>"},
  {id: 33, imagePath: "/static/images/materiali_informativi/percorso_3.png", title: "3. Ingegnerizzazione", description: "Nei linfociti T viene inserito il recettore CAR, attraverso un processo controllato<sup>1</sup> <br><br>  <strong>Durante questo periodo di 3/4 settimane il paziente viene attentamente monitorato<sup>1</sup></strong>"},
  {id: 4, imagePath: "/static/images/materiali_informativi/percorso_4.png", title: "4. Chemioterapia di condizionamento", description: "Il paziente è sottoposto a chemioterapia per ridurre il numero di  linfociti<sup>1</sup>"},
  {id: 55, imagePath: "/static/images/materiali_informativi/percorso_5.png", title: "5. Somministrazione della terapia", description: "Al paziente sono somministrate le CAR-T<sup>1</sup>"},
  {id: 6, imagePath: "/static/images/materiali_informativi/percorso_6.png", title: "6. Monitoraggio", description: "Dopo il trattamento il paziente è monitorato da un team multidisciplinare <strong>formato nella gestione di possibili eventi avversi<sup>1</sup></strong> <br><br> <strong>Per le prime settimane è richiesto al paziente e di stare vicino alla struttura ospedaliera per essere monitorato<sup>1</sup></strong>"},
  {id: 77, imagePath: "/static/images/materiali_informativi/percorso_7.png", title: "7. Valutazione del tumore", description: "Dopo 1-3 mesi dalla somministrazione delle CAR-T può essere effettuato un primo controllo per la valutazione dello stato del tumore<sup>1</sup>"},
];

export default function MaterialiInformativiLayout(props) {
  const classes = { paper: "paper", root: "root fullscreen" };
  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ['materiali_informativi_single_subtitle', 'prefooter_materiali_informativi_content'];

  const getStrings = async () => {
    try {
      const response = await axios.post('/strings/list/subset', { keys: stringKeys }); 
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStrings();
  }, []);

  return (
    <>
      <Box>
        <FrontendAppMenu />
        <PageHeader 
          title="Materiali Informativi"
          subtitle={strings['materiali_informativi_single_subtitle']}
        />
        <Container
          maxWidth="false"
          sx={{
            padding: {
              xs: "40px 20px !important",
              md: "40px 54px !important"
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: "secondary.main",
              borderRadius: "12px",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ md: 2 }}
              direction="row"
              margin="0 auto"
              sx={{
                padding: {
                  xs: "20px",
                  sm: "30px 60px",
                  lg: "40px 80px"
                },
              }}
            >
              <Grid item xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h1"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    fontSize: "36px",
                    textAlign: "center",
                  }}
                >
                  Le cellule CAR-T
                </Typography>
              </Grid>
              {boxes.map((box) => (
                <Grid item xs={12} md={4} key={box.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box style={styles.boxWrapper} >
                    <Box
                      component="img"
                      sx={{
                        maxWidth: "100%",
                      }}
                      src={box.imagePath}
                    />
                    <Typography variant="h6" style={styles.boxTitle}>
                      {box.title}
                    </Typography>
                    <Typography style={styles.boxContent}>
                      {ReactHtmlParser(
                        box.content
                      )}
                    </Typography>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px"
                }}
              >
                <Typography variant="whiteOnColor" align="center" >
                  {
                    ReactHtmlParser(
                      "Grazie alla presenza del recettore CAR le cellule <strong>CAR-T</strong> sono in grado di <strong>riconoscere in modo specifico le cellule cancerose ed eliminarle.<sup>2</sup></strong> Inoltre, le cellule CAR-T sono emerse come terapia efficace, in grado di indurre remissioni durevoli in pazienti che in precedenza avevano un bisogno medico insoddisfatto.<sup>1</sup>"
                    )
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: {xs: "20px 0", md: "40px 80px"} }} >
            <Box>
              <Typography variant="notBlackOnColor" sx={{ fontSize: "13px !important" }} >
                1. Abramson JS, et al., Am Soc Clin Oncol Educ Book. 2019;39:446-453.
              </Typography>
            </Box>
            <Box>
              <Typography variant="notBlackOnColor" sx={{ fontSize: "13px !important" }} >
                2. Mohanty R, et al., Oncol Rep. 2019;42(6):2183-2195.
              </Typography>
            </Box>
            <Box>
              <Typography variant="notBlackOnColor" sx={{ fontSize: "13px !important" }} >
                3. Liu Y and  He Y. Ann Transl Med. 2021 9(9):808.
              </Typography>
            </Box>
          </Box>
        </Container>
        <Container
          maxWidth="false"
          sx={{
            padding: "40px 54px !important",
            backgroundImage: "url(/static/images/services_bg.png), linear-gradient(rgba(44, 164, 222, .1), rgba(44, 164, 222, .0))",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
          }}
        >
          <Grid 
            container
            maxWidth="xl"
            spacing={0}
            direction="row"
            margin="0 auto"
            sx={{
              padding: {
                sm: "20px"
              },
            }}
          >
            <Grid item xs={12} >
              <Typography
                variant="h4"
                align="center"
                sx={{ 
                  mb: "40px",
                  fontWeight: "bold",
                  color: "#2CA4DE",
                  fontSize: {
                    xs: "30px",
                    sm: "35px",
                    md: "40px"
                  }
                }}
              >
                Il percorso di cura
              </Typography>
            </Grid>
            {paths.map((path) => (
              <Grid 
                container
                item
                key={path.id}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: path.id%2 != 0 ? "row-reverse" : "row",
                  justifyContent: "center",
                  marginBottom: "70px"
                }}
              >
                <Grid item xs={12} md={6} key={`${path.id}-1`}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      maxWidth: {
                        xs: "100%",
                        sm: "80%"
                      },
                    }}
                    src={path.imagePath}
                  />
                </Grid>
                <Grid item xs={12} md={6} key={`${path.id}-2`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: {
                      xs: "0",
                      sm: "0 30px",
                      md: "0 60px"
                    },
                  }}
                >
                  <Box>
                    <Typography variant="h6" style={styles.pathTitle}>
                      {
                        ReactHtmlParser(
                          path.title
                        )
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="notBlackOnColor" style={styles.pathDescription}>
                      {
                        ReactHtmlParser(
                          path.description
                        )
                      }
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ width: "100%", display: "inline-block", padding: {md: "40px 80px"} }} >
            <Box sx={{display: "inline-block", width: { xs: "100%", md: "50%" }, float: "left"}}>
              <Typography variant="notBlackOnColor" sx={{ fontSize: "13px !important" }} >
                1. Jacobson CA, et al., Oncologist. 2020;25(1):e138-e146.
              </Typography>
            </Box>
            <Box sx={{display: "inline-block", width: { xs: "100%", md: "50%" }, float: "right", textAlign: { xs: "left", md: "right" } }}>
              <Typography variant="notBlackOnColor" sx={{ fontSize: "13px !important" }} >
                Elaborazione grafica da testo Ref. 1
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <PreFooter content={strings['prefooter_materiali_informativi_content']} />
    </>
  );
}

const styles = {
  boxWrapper: {
    width: "100%",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "12px",
    textAlign: "center"
  },
  boxTitle: {
    padding: "20px 0", 
    fontSize: "18px",
    fontWeight: "bold",
    color: "#2CA4DE"
  },
  boxContent: {
    fontSize: "13px",
    color: "#494A4C"
  },
  pathTitle: {
    padding: "20px 0", 
    fontSize: "18px",
    fontWeight: "bold",
    color: "#494A4C"
  },
  pathDescription: {
    fontSize: "13px",
    color: "#494A4C"
  }
}