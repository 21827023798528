import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#004990",
      alternative: "#B50026",
    },
    secondary: {
      main: "#2CA4DE",
    },
    error: {
      main: red.A400,
    },
    tertiary: {
      main: "#B50026",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 22,
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "blueColored" },
          style: {
            backgroundColor: "#004990",
            color: 'white',
            "&:hover": {
              backgroundColor: "rgba(0, 77, 144, 0.8)",
              color: 'white',
            }
          }
        },
        {
          props: { variant: "whiteBlueColored" },
          style: {
            backgroundColor: "white",
            color: '#004990',
            "&:hover": {
              backgroundColor: "#004990",
              color: 'white',
            }
          }
        },
        {
          props: { variant: "redColored" },
          style: {
            backgroundColor: "#B50026",
            color: 'white',
            "&:hover": {
              backgroundColor: "rgba(181, 0, 38, 0.8)",
              color: 'white',
            }
          }
        }
      ]
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#494A4C"
        },
      },
      variants: [
        {
          props: { variant: "noSpaceParagraph" },
          style: {
            '& p': {
              margin: "0"
            }
          }
        },
        {
          props: { variant: "inline" },
          style: {
            display: "inline",
          }
        }
      ]
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    banner: {
      fontSize: "18px",
    },
    commonLaunch: {
      fontSize: "20px",
    },
    whiteOnColor: {
      fontSize: "16px",
      color: "#fff !important",
    },
    notBlackOnColor: {
      fontSize: "16px",
      color: "#494A4C",
    },
  },
});

export default theme;
