import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { Box, ThemeProvider } from "@mui/system";

import { Container } from "@material-ui/core";
import axios from "../utils/axios";

import { Typography } from "@material-ui/core";

import { Button } from "@mui/material";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

export default function RoundedBox(props) {
  const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Container
      maxWidth="false"
      sx={{
        padding: {
          xs: "40px 20px !important",
          sm: "40px 35px !important",
          md: "40px 54px !important",
        },
        ...props.sx?.roundedBox,
      }}
    >
      <Grid
        container
        maxWidth="xl"
        spacing={0}
        direction="row"
        margin="0 auto"
        sx={{
          backgroundColor: "secondary.main",
          borderRadius: "12px",
          padding: {
            xs: "20px 40px",
            md: "40px 80px",
          },
          ...props.sx?.boxWrapper,
        }}
      >
        {props.title ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              color="white"
              sx={{
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "36px",
                textAlign: "center",
                "& a": {
                  color: "inherit",
                },
                ...props.sx?.title,
              }}
            >
              {ReactHtmlParser(
                SlateSerializer({ children: JSON.parse(props.title) })
              )}
            </Typography>
          </Grid>
        ) : null}
        {props.link ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2rem",
            }}
          >
            <Button
              component={Link}
              to={props.link}
              fullWidth
              color="primary"
              variant="blueColored"
              sx={{
                float: "left",
                width: "auto",
                clear: "both",
              }}
            >
              Scopri di più
            </Button>
          </Grid>
        ) : null}
        {props.description ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="whiteOnColor">
              {ReactHtmlParser(
                SlateSerializer({ children: JSON.parse(props.description) })
              )}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {props.noHtmlDescription
            ? props.noHtmlDescription.split("\n").map((line, index) => (
                <Typography variant="whiteOnColor" key={index}>
                  {line}
                </Typography>
              ))
            : ""}
        </Grid>
      </Grid>
      {props.subContent ? (
        <Box sx={{ padding: { xs: "20px 40px", md: "40px 80px" } }}>
          <Typography variant="notBlackOnColor">
            {ReactHtmlParser(
              SlateSerializer({ children: JSON.parse(props.subContent) })
            )}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </Container>
  );
}
