// import * as React from 'react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
// Formik
import { useFormik } from 'formik';

import { Input, Typography } from '@material-ui/core';
import { Button, Icon, Toolbar, Alert, TextField, FormHelperText } from '@mui/material';
// Hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// Utils
import axiosInstance from '../../../utils/axios';
// slate editor
import SlateEditor from '../../SlateEditor';

import { withReact } from 'slate-react'
import {
  Editor,
  createEditor
} from 'slate'
import { withHistory } from 'slate-history'
import { CollectionsBookmarkRounded } from '@material-ui/icons';

const serviceForm = (props) => {
  const [service, setService] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState('');
  // slate editor
  const [slateText, setStateText] = useState('');
  const [description, setDescription] = useState('');
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const isMountedRef = useIsMountedRef();

  const handleFileChange = (event) => {
    formik.handleChange(event);
    const file = event.target.files[0];
    setSelectedFile(file);
  }

  const handleError = (error) => {
    props.handleError(JSON.stringify(error));
  }

  const handleEditorChange = (value) => {
    setStateText(value);
  }

  useEffect(() => {
    if (props.service) {
      setService(props.service);
      setIsEdit(true);
      setDescription(props.service.description);
    } else {
      setIsEdit(false);
    }
  }, [props.service]);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
  ];

  Yup.addMethod(Yup.mixed, "customRequiredImage", function (errorMessage) {
    return this.test(`test-custom-required`, errorMessage, function (value) {
      const { path, createError } = this;
  
      return (
        (service.image && service.image !== "" || value && value !== undefined) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const CreateSchema = Yup.object().shape({
    title: Yup.string()
      .required('Titolo obbligatorio.'),
    // description: Yup.string()
    //   .required('Description required'),
    link: Yup.string(),
    image: Yup.mixed()
      .customRequiredImage("Immagine obbligatoria.")
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: service.title ? service.title : '',
      description: service.description ? service.description : '',
      link: service.link ? service.link : '',
      image: '' // service.image ? service.image : '',
    },
    validationSchema: CreateSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const editorText = JSON.parse(slateText);
        let editorEmpty = true;

        // Check if editor is empty
        for (let node of editorText) {
          if (!Editor.isEmpty(editor, node)) {
            editorEmpty = false;
            break;
          }
        }

        if (editorEmpty) {
          handleError('Description is empty');
          throw new Error('Description is required');
        }

        const data = new FormData() 
        data.append('title', values.title);
        data.append('description', slateText); // values.description
        data.append('link', values.link);
        data.append('image', selectedFile);

        let url = ``;

        if (isEdit) {
          url = `/services/update/${service.id}`;
        } else {
          url = `/services/create`;
        }

        await axiosInstance.post(url, data)
          .then(response => {
            setService(response.data.service);
            setAlertSuccess(`Service has been ${isEdit ? 'updated' : 'created'}`);
          })
          .catch(error => {
            console.error(error.data);
            handleError(error.data);
          });
        
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.data });
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.data });

          if (error.status !== 401) {
            resetForm();
          }

          if (error.status === 401) {
            setErrors({ afterSubmit: translate('auth.login.username_not_validated') });
          }

          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

      {alertSuccess && <Alert severity="success">{alertSuccess}</Alert>}
      
      <TextField
          id="title"
          name="title"
          autoComplete="title"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          placeholder='Title'

          margin="normal"
          fullWidth
          autoFocus
      />

      {/* <TextField
        id="description"
        name="description"
        // autoComplete="description"
        onChange={formik.handleChange}

        value={formik.values.description}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        placeholder="Description"

        margin="normal"
        fullWidth
        multiline
      /> */}

      <SlateEditor initialValue={description} handleChange={handleEditorChange} placeholder='Description' />

      <TextField
        id="link"
        name="link"
        // autoComplete="link"
        onChange={formik.handleChange}
        value={formik.values.link}
        error={formik.touched.link && Boolean(formik.errors.link)}
        helperText={formik.touched.link && formik.errors.link}
        placeholder="Link"

        margin="normal"
        fullWidth
      />

      <Input
        type="file"
        id="image"
        name="image"
        
        onChange={handleFileChange}
        error={formik.touched.image && Boolean(formik.errors.image)}
        // helperText={formik.touched.image && formik.errors.image}

        sx={{mt: 1}}
        fullWidth
      />
      {/* Image input helpers */}
      { formik.errors.image && <FormHelperText error>{formik.errors.image}</FormHelperText> }
      { service.image && <Typography sx={{mb: 1, fontSize: 14}}>Image is already setted, upload a new one to update the existing</Typography> }

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {
          isEdit 
            ? 'Update' 
            : 'Create'
        }
      </Button>

    </form>
  );
}

export default serviceForm;