import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
//import Login from '../pages/authentication/Login';

import LoginForm from '../components/LoginForm';

import { PATH_MAIN, PATH_ADMIN, PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default function AdminGuard({ children }) {
  const { isAuthenticated, role } = useAuth();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    /*if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginForm />;*/
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (role !== 'ADMIN') {
    return <Navigate to={PATH_MAIN.main.dashboard} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
