import { useState, useEffect } from "react";

import { Link } from "@mui/material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";
// paths
import { PATH_PRIVACY } from '../routes/paths';
import CookieConsent from "react-cookie-consent";

export default function CookieBar() {
    const classes = { paper: 'paper', root: 'root fullscreen' };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accetta"
            cookieName="cookieAcceptance"
            style={{ background: "#2CA4DE", padding: ".8rem", color: "white" }}
            buttonStyle={{ color: "white", fontSize: "14px", backgroundColor: "rgba(0, 77, 144, 1)", borderRadius: "50px", padding: '4px 13px' }}
            expires={150}
            acceptOnScroll={true}
            acceptOnScrollPercentage={20}
        >
            Questo sito fa uso di cookie tecnici per migliorare l'esperienza utente.{" "}
            <Link href={PATH_PRIVACY.main.cookies} style={styles.link}>Maggiori informazioni</Link>
        </CookieConsent>
    );
}

const styles = {
    link: {
        color: 'white',
        textDecoration: 'underline',
        fontSize: '13px',
    }
}