import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
//import Login from '../pages/authentication/Login';

import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoginForm from '../components/LoginForm';

import { PATH_MAIN, PATH_ADMIN, PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, expireAccessDate, role, logout } = useAuth();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [showAccessAlert, setShowAccessAlert] = useState(sessionStorage.getItem('hidingExpireAccessAlert') ? false : true);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const closeAccessAlert = () => {
    sessionStorage.setItem('hidingExpireAccessAlert', true);
    setShowAccessAlert(false);
  }

  const getRemainingDays = (date2, date1) => {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      // Discard the time and time-zone information.
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY); // days
  }

  // Check if access date is expired
  if (expireAccessDate !== null && new Date() > expireAccessDate && role === 'USER') {
    logout();
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (!isAuthenticated) {
    /*if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginForm />;*/
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>
      {
        showAccessAlert && expireAccessDate !== null && getRemainingDays(expireAccessDate, new Date()) <= 30 && role !== 'ADMIN' &&
        <Alert 
          onClose={closeAccessAlert}
          severity="info"
          iconMapping={{
            info: <InfoOutlinedIcon fontSize="inherit" sx={{ color: "white" }} />,
          }}
          sx={{
            zIndex: 900, 
            position: "fixed",
            bottom: 0,
            width: "100%",
            padding: "1rem",
            backgroundColor: "#2CA4DE",
            color: "white",
            borderRadius: 0,
          }} 
        >
          {
            `Hai a disposizione ancora ${getRemainingDays(expireAccessDate, new Date())} giorni di utilizzo.`
          }
        </Alert>
      }
      {children}
    </>
  );
}
