import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Link from '@mui/material/Link';
import { Box, ThemeProvider } from "@mui/system";
import Container from "@mui/material/Container";
import { Typography } from "@material-ui/core";
// Modal
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
// utils
import axios from "../../../utils/axios";
// hooks
import useAuth from "../../../hooks/useAuth";
// components
import EditDeleteBox from "../EditDeleteBox";
import CustomModal from "../../CustomModal";
import { ConstructionOutlined } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function StringBox(props) {
  const { user } = useAuth();
  const classes = { paper: "paper", root: "root fullscreen" };
  const [strings, setStrings] = useState([]);
  const [selectedString, setSelectedString] = useState(null);
  // Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (string_id) => {
    setOpenModal(true);
    setSelectedString(string_id);
  }
  const handleClose = () => setOpenModal(false);

  const handleDelete = async () => {
    try {
      const response = await axios.post(`/strings/delete/${selectedString}`);

      setOpenModal(false);

      setStrings(strings.filter((string) => string.id !== selectedString));

      props.showAlert("String deleted successfully");
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllStrings();
  }, []);

  const getAllStrings = () => {
    const data = {
        limit: props.items,
    };

    axios
        .post("/strings/list", data)
        .then((res) => {
            const allStrings = res.data.strings;
            setStrings(allStrings);
        })
        .catch((err) => {
            console.error(err);
        });
  };

  return (
    <Container maxWidth="false" sx={{ pt: "40px", pb: "40px" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container maxWidth="xl" spacing={2}>
          {strings.map((string, index) => {
            return (
              <Grid key={string.id} item xs={12} sm={6}>
                <Box
                  key={string.id}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    padding: "1rem",
                    textAlign: "center",
                    width: "100%",
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {string.key}
                  </Typography>

                  <EditDeleteBox items='strings' itemId={string.id} handleDelete={() => {handleOpen(string.id)}} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <CustomModal open={openModal} handleNo={handleClose} handleYes={handleDelete} title='Do you want to delete the string?' />
    </Container>
  );
}
