import * as React from 'react';
import { Link, useNavigate, Navigate } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const mainMenuItems = (
  <React.Fragment>
    <Link to="/admin/dashboard">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const servicesMenuItems = (
  <React.Fragment>
    {/* Services menu */}
    <ListSubheader component="div" inset>
      Services
    </ListSubheader>
    <Link to="/admin/services/list">
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/services/create" state={{ reload: true }}> 
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Create" />
      </ListItemButton>
    </Link>
    {/* Strings menu */}
    <ListSubheader component="div" inset>
      Strings
    </ListSubheader>
    <Link to="/admin/strings/list">
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="List" />
      </ListItemButton>
    </Link>
    <Link to="/admin/strings/create" state={{ reload: true }}> 
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Create" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);