import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Link from '@mui/material/Link';
import { Box, ThemeProvider } from "@mui/system";
import Container from "@mui/material/Container";
import { Typography } from "@material-ui/core";
// utils
import axios from "../../../utils/axios";
// hooks
import useAuth from "../../../hooks/useAuth";
// components
import EditDeleteBox from "../EditDeleteBox";
import CustomModal from "../../CustomModal";

import BasicTabs from "../../LoginRegisterTabs";

export default function ServiceAdminBox(props) {
  const { user } = useAuth();
  const classes = { paper: "paper", root: "root fullscreen" };
  const [services, getServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  // Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (service_id) => {
    setOpenModal(true);
    setSelectedService(service_id);
  }
  const handleClose = () => setOpenModal(false);

  const handleDelete = async () => {
    try {
      const response = await axios.post(`/services/delete/${selectedService}`);

      setOpenModal(false);

      getServices(services.filter((service) => service.id !== selectedService));

      props.showAlert("Service deleted successfully");
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllServices();
  }, []);

  const getAllServices = () => {
    const data = {
        limit: props.items,
    };

    axios
        .post("/services/listAll", data)
        .then((res) => {
            const allServices = res.data.services;
            getServices(allServices);
        })
        .catch((err) => {
            console.error(err);
        });
  };

  return (
    <Container maxWidth="false" sx={{ pt: "40px", pb: "40px" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container maxWidth="xl" spacing={2}>
          {services.map((service, index) => {
            return (
              <Grid key={service.id} item xs={12} sm={6}>
                <Box
                  sx={{
                    // backgroundColor: "#fff",
                    // borderRadius: "12px",
                    // padding: "2rem",
                    // textAlign: "center",
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    padding: "1rem",
                    textAlign: "center",
                    width: "100%",
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  >
                    <Link href={`/admin/services/update/${service.id}`} underline="none" color="inherit">
                        {service.title}
                    </Link>
                  </Typography>

                  <EditDeleteBox items='services' itemId={service.id} handleDelete={() => {handleOpen(service.id)}} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <CustomModal open={openModal} handleNo={handleClose} handleYes={handleDelete} title='Do you want to delete the service?' />
    </Container>
  );
}
