import jwtDecode from 'jwt-decode';
//import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  // Verify if user is logged with valid otp
  if (!decoded.otp_verified) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getTokenData = (accessToken) => {
  if (!accessToken) {
    return {};
  }
  return jwtDecode(accessToken);
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  // expiredTimer = window.setTimeout(() => {
  //   // You can do what ever you want here, like show a notification
  // }, timeLeft);
};

const setSession = async (accessToken, refreshToken, role) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('role');
    // delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.Authorization;
  }
};

export { isValidToken, setSession, getTokenData };