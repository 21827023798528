import Grid from "@material-ui/core/Grid";

import { Box, ThemeProvider } from "@mui/system";

import { Container } from "@material-ui/core";

import { useState, useEffect } from "react";
import axios from "../utils/axios";

import { Typography } from "@material-ui/core";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

export default function CenteredLaunch(props) {
  const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Container
      maxWidth="false"
      sx={{
        pt: "40px",
        pb: "40px",
      }}
    >
      <Grid
        container
        maxWidth="xl"
        spacing={0}
        direction="row"
        margin="0 auto"
        alignItems="center"
      >
        <Grid item xs={false} sm={2} md={2} lg={2} />
        <Grid item xs={12} sm={8} md={8} lg={8} textAlign="center">
          <Typography variant="commonLaunch" color="textSecondary">
            {
              props.description
              ? ReactHtmlParser(
                  SlateSerializer({ children: JSON.parse((props.description)) })
                )
              : ''
            }
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
