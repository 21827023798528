import * as React from 'react';
import { useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';

import Copyright from './components/Copyright';

import Router from './routes';
// utils
import { useAnalytics } from 'use-analytics';
import { useLocation } from 'react-router-dom';

export default function App() {
  let location = useLocation();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [location]);

  return (
    <Router />
  );
}
