import { Box } from "@mui/system";
import { Typography } from "@material-ui/core";
// components
import PageHeader from "../../components/PageHeader";

export default function InformativaPrivacy() {
  return (
    <Box
      sx={{
        padding: {
          xs: "20px",
          sm: "35px",
          md: "54px"
        },
        paddingBottom: {
          xs: "100px !important",
          md: "150px !important"
        }
      }}
    >
      <PageHeader
        title="Politica cookies"
      />
      <Box style={styles.boxParagraph}>
        <Typography>
          Il nostro sito fa uso di cookies, al fine di agevolare la navigazione, effettuare delle statistiche e rendere i propri servizi il più possibile efficienti e semplici da utilizzare. Non vengono utilizzati per scopi ulteriori. Utilizzando il nostro sito web, l'utente dichiara di accettare e acconsentire all’ utilizzo dei cookies in conformità con i termini di uso dei cookies espressi in questo documento.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          I cookie sono file di testo che contengono pacchetti di informazioni che vengono memorizzati sul tuo computer o sul tuo dispositivo mobile tutte le volte che visiti un sito online attraverso un browser. Ad ogni successiva visita il browser invia questi cookies al sito web che li ha originati o ad un altro sito. I cookies permettono ai siti di ricordare alcune informazioni per permetterti di navigare online in modo semplice e veloce.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          Questo sito internet utilizza solo cookies tecnici ed analitici, strettamente necessari per la fornitura del servizio. Qui di seguito sono riportati i tipi cookie tecnici utilizzati nel Sito con una descrizione della finalità legata all'uso.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={styles.italic}>
          Cookie di navigazione
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          Garantiscono all’utente la normale fruizione di un sito web, ad esempio l’accesso ad una particolare area riservata.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={styles.italic}>
          Cookie di Analytics
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          Rientrano nella categoria dei cookie tecnici se i dati raccolti sono in forma aggregata. Questo tipo di cookie raccoglie informazioni in forma anonima sull'attività degli utenti nel sito e sul modo in cui sono arrivati al Sito e alle pagine visitate. I cookie di questa categoria vengono inviati dal Sito stesso o da domini di terze parti.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={styles.italic}>
          Cookie di funzionalità
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          Consentono la navigazione e la corretta fruizione di un sito internet sulla base di selezioni effettuate dall’utente, ad esempio la scelta della lingua o, nel caso di un e-commerce, i prodotti inseriti nel carrello.
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          Il sito può includere anche talune componenti trasmesse da Google Analytics, un servizio di analisi del traffico web fornito da Google, Inc. (“Google”). Anche in questo caso si tratta di cookie di terze parti raccolti e gestiti in modo anonimo per monitorare e migliorare le prestazioni del sito ospite (performance cookie). Google Analytics utilizza i “cookie” per raccogliere e analizzare in forma anonima le informazioni sui comportamenti di utilizzo del sito web (compreso l’indirizzo IP dell’utente). Tali informazioni vengono raccolte da Google Analytics, che le elabora allo scopo di redigere report per i gestori del sito riguardanti le attività sui siti web stessi. Questo sito non utilizza (e non consente a terzi di utilizzare) lo strumento di analisi di Google per monitorare o per raccogliere informazioni personali di identificazione. Google non associa l’indirizzo IP a nessun altro dato posseduto da Google né cerca di collegare un indirizzo IP con l’identità di un utente. 
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography>
          L’utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser. Attenzione: la disabilitazione totale o parziale dei cookie tecnici può compromettere l’utilizzo delle funzionalità del sito riservate agli utenti registrati. 
        </Typography>
      </Box>
      <Box style={styles.boxParagraph}>
        <Typography style={styles.italic}>
          Come disabilitare i cookie
        </Typography>
      </Box>
      <Box>
          <Typography variant="inline" style={styles.bold}>Chrome </Typography>
          <Typography variant="inline"> 
            1. Eseguire il Browser Chrome 2. Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra di inserimento url per la navigazione 3. Selezionare Impostazioni 4. Fare clic su Mostra Impostazioni Avanzate 5. Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“ 6. Nella sezione “Cookie” è possibile modificare le seguenti impostazioni relative ai cookie: Consentire il salvataggio dei dati in locale Modificare i dati locali solo fino alla chiusura del browser Impedire ai siti di impostare i cookie Bloccare i cookie di terze parti e i dati dei siti Gestire le eccezioni per alcuni siti internet Eliminazione di uno o tutti i cookie Per maggiori informazioni visita la pagina dedicata. - 
          </Typography>
          
          <Typography variant="inline" style={styles.bold}> Mozilla Firefox </Typography>
          <Typography variant="inline">
            1. Eseguire il Browser Mozilla Firefox 2. Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra di inserimento url per la navigazione 3. Selezionare Opzioni 4. Seleziona il pannello Privacy 5. Fare clic su Mostra Impostazioni Avanzate 6. Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“ 7. Nella sezione “Tracciamento” è possibile modificare le seguenti impostazioni relative ai cookie: Richiedi ai siti di non effettuare alcun tracciamento Comunica ai siti la disponibilità ad essere tracciato Non comunicare alcuna preferenza relativa al tracciamento dei dati personali 8. Dalla sezione “Cronologia” è possibile: Abilitando “Utilizza impostazioni personalizzate” selezionare di accettare i cookie di terze parti (sempre, dai siti più visitato o mai) e di conservarli per un periodo determinato (fino alla loro scadenza, alla chiusura di Firefox o di chiedere ogni volta) Rimuovere i singoli cookie immagazzinati - 
          </Typography>

          <Typography variant="inline" style={styles.bold}> Internet Explorer </Typography>
          <Typography variant="inline">
            1. Eseguire il Browser Internet Explorer 2. Fare click sul pulsante Strumenti e scegliere Opzioni Internet 3. Fare click sulla scheda Privacy e nella sezione Impostazioni modificare il dispositivo di scorrimento in funzione dell’azione desiderata per i cookie: Bloccare tutti i cookie Consentire tutti i cookie Selezione dei siti da cui ottenere cookie: spostare il cursore in una posizione intermedia in modo da non bloccare o consentire tutti i cookie, premere quindi su Siti, nella casella Indirizzo Sito Web inserire un sito internet e quindi premere su Blocca o Consenti  - 
          </Typography>

          <Typography variant="inline" style={styles.bold}> Safari 6 </Typography>
          <Typography variant="inline">
            1. Eseguire il Browser Safari 2. Fare click su Safari, selezionare Preferenze e premere su Privacy 3. Nella sezione Blocca Cookie specificare come Safari deve accettare i cookie dai siti internet 4. Per visionare quali siti hanno immagazzinato i cookie cliccare su Dettagli - 
          </Typography>

          <Typography variant="inline" style={styles.bold}> Safari iOS (dispositivi mobile) </Typography>
          <Typography variant="inline">
            1. Eseguire il Browser Safari iOS 2. Tocca su Impostazioni e poi Safari 3. Tocca su Blocca Cookie e scegli tra le varie opzioni: “Mai”, “Di terze parti e inserzionisti” o “Sempre” 4. Per cancellare tutti i cookie immagazzinati da Safari, tocca su Impostazioni, poi su Safari e infine su Cancella Cookie e dati - 
          </Typography>

          <Typography variant="inline" style={styles.bold}> Opera </Typography> 
          <Typography variant="inline">
            1. Eseguire il Browser Opera 2. Fare click sul Preferenze poi su Avanzate e infine su Cookie 3. Selezionare una delle seguenti opzioni: Accetta tutti i cookie Accetta i cookie solo dal sito che si visita: i cookie di terze parti e che vengono inviati da un dominio diverso da quello che si sta visitando verranno rifiutati Non accettare mai i cookie: tutti i cookie non verranno mai salvati.
          </Typography>
      </Box>
    </Box>
  );
}

const styles = {
  boxParagraph: {
    marginBottom: '20px',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
}