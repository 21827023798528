import { useState } from "react";

import { Box, ThemeProvider } from '@mui/system';
import { Alert } from '@mui/material';

import ServiceBox from './ServiceAdminBox';

export default function ServiceList(props) {
    const classes = { paper: 'paper', root: 'root fullscreen' };
    const [alertMessage, setAlertMessage] = useState('');

    const handleAlertMessage = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage('');
        } , 3000);
    }

    return (
        <Box
            sx={{}}
        >
            {alertMessage && <Alert severity="success">{alertMessage}</Alert>}

            <ServiceBox items={15} showAlert={handleAlertMessage}></ServiceBox>
        </Box>
    );
} 