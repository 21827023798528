import { Box } from "@mui/system";
import { Typography } from "@material-ui/core";
// components
import PageHeader from "../../components/PageHeader";

export default function InformativaPrivacy() {
  return (
    <Box
      sx={{
        padding: {
          xs: "20px",
          sm: "35px",
          md: "54px"
        },
        paddingBottom: {
          xs: "100px !important",
          md: "150px !important"
        }
      }}
    >
      <PageHeader
        title="Termini d'uso"
      />
      <Box>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae bibendum sapien, id ullamcorper nisi. Mauris non elementum lorem. In porttitor ac eros quis blandit. Etiam scelerisque blandit egestas. Vestibulum iaculis diam id magna lacinia, nec gravida mauris pretium. Nullam ex urna, laoreet vitae molestie eget, congue vitae diam. Phasellus metus lectus, rutrum eu odio at, pulvinar lacinia lectus. Cras molestie non est id elementum. Duis sollicitudin placerat tincidunt. Nulla dictum eleifend dictum. In eget ultricies nibh. Pellentesque enim turpis, suscipit a venenatis eu, vulputate sed arcu.
        </Typography>
      </Box>
      <Box>
        <Typography>
          Vivamus ultrices iaculis nisi quis hendrerit. In hac habitasse platea dictumst. In et nisl vel ex volutpat placerat. Mauris malesuada consectetur lectus tempor molestie. Aenean vitae mattis odio. Pellentesque bibendum, nibh vel fringilla gravida, ex ligula venenatis metus, vel sollicitudin purus lorem nec tellus. Nam vel semper turpis, ac volutpat nisi.
        </Typography>
      </Box>
      <Box>
        <Typography>
          Ut laoreet est maximus lacus cursus bibendum nec ac lorem. Nunc sollicitudin, metus nec accumsan dapibus, est nulla laoreet nibh, non fringilla quam erat ac justo. Quisque tellus tortor, ultricies eget lobortis non, dignissim ut nulla. Maecenas pharetra malesuada congue. Fusce iaculis gravida maximus. Vestibulum nec mauris ac nisi lacinia posuere eget id urna. Quisque convallis facilisis aliquet. Sed lorem nisl, molestie in maximus ac, finibus ut eros. Fusce neque turpis, tempor ut nibh sit amet, ullamcorper eleifend est. Sed id sodales felis. Morbi aliquam feugiat efficitur. Aliquam accumsan, nisi eget dapibus vestibulum, tellus nisi maximus tellus, ut commodo odio arcu vitae felis.
        </Typography>
      </Box>
      <Box>
        <Typography>
          Ut efficitur nisl id orci tristique, in fermentum magna bibendum. Ut finibus est in diam tincidunt, sit amet feugiat ante tempus. Donec vel risus eu nulla vehicula scelerisque. Phasellus sagittis lobortis dolor, sit amet aliquet nibh rhoncus vel. Sed non mauris ac urna malesuada vehicula. Proin augue sapien, vehicula at nisl posuere, porttitor consectetur sem. Donec non ante ac.
        </Typography>
      </Box>
    </Box>
  );
}