import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// paths
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

OtpGuard.propTypes = {
  children: PropTypes.node
};

export default function OtpGuard({ children }) {
  const { isOtpVerified } = useAuth();

  if (isOtpVerified !== false) {
    return <Navigate to={PATH_AUTH.login} />;
  }
  
  return <>{children}</>;
}
