import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// Yup
import * as Yup from 'yup';
// Formik
import { useFormik } from 'formik';

import InputAdornment from '@mui/material/InputAdornment';
// icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// hooks
import useAuth from '../hooks/useAuth';
import useIsMountedRef from '../hooks/useIsMountedRef';
// paths
import { PATH_PRIVACY, PATH_AUTH } from '../routes/paths';

export default function RegisterForm (props) {
    const { register } = useAuth();
    const isMountedRef = useIsMountedRef();

    const navigate = useNavigate();
  
    const RegisterSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email non valida.')
            .required('Email obbligatoria.'),
        password: Yup.string().required('Password obbligatoria.'),
        password_confirmation: Yup.string().required('Conferma password obbligatoria.'),
        code: Yup.string().required('Codice obbligatorio.'),
        name: Yup.string(),
        lastname: Yup.string(),
    });
  
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
        password_confirmation: '',
        code: '',
      },
      validationSchema: RegisterSchema,
      onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
        try {
            await register(values.email, values.password, values.password_confirmation, values.code, values.name, values.lastname);
            if (isMountedRef.current) {
                setSubmitting(false);
            }

            // Redirect to otp verification page
            window.location.href = '/auth/otp/verify';
        } catch (error) {
          console.error(error.data);
          
          const errorMessage = Object.values(error.data)[0][0];
          setErrors({ afterSubmit: errorMessage });
          resetForm();
  
          if (isMountedRef.current) {
            if (error.status === 422) {
                setErrors({ afterSubmit: errorMessage });
            } else {
                setErrors({ afterSubmit: error.data.message });
            }
  
            setSubmitting(false);
          }
        }
      }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (

        <Container component="div" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Typography component="h1" variant="h4" sx={{ color: '#004990', fontWeight: 'bold' }}>
                    Crea un account
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    {errors.afterSubmit && <Alert severity="error" sx={{mt: 2, mb: 1}}>{errors.afterSubmit}</Alert>}

                    <TextField
                        margin="normal"
                        variant="standard"
                        
                        fullWidth
                        id="code"
                        placeholder="Codice di sicurezza"
                        name="code"
                        autoComplete="code"
                        autoFocus
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        sx={styles.inputText}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutlineOutlinedIcon sx={{ color: "#6369E9" }} />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                    />

                    <TextField
                        margin="normal"
                        variant="standard"
                        
                        fullWidth
                        id="email"
                        placeholder="Indirizzo email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={styles.inputText}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <MailOutlineIcon sx={{ color: "#6369E9" }} />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                    />

                    <TextField
                        margin="normal"
                        variant="standard"

                        fullWidth
                        name="password"
                        placeholder="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={styles.inputText}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <LockOutlinedIcon sx={{ color: "#6369E9" }} />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                    />

                    <TextField
                        margin="normal"
                        variant="standard"

                        fullWidth
                        name="password_confirmation"
                        placeholder="Conferma Password"
                        type="password"
                        id="password_confirmation"
                        autoComplete="current-password"
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                        helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                        sx={styles.inputText}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <LockOutlinedIcon sx={{ color: "#6369E9" }} />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                    />

                    <Typography
                        sx={{
                            paddingTop: "10px",
                            fontSize: "11px"
                        }}
                    >
                        Presa visione dell’<Link href={PATH_PRIVACY.main.informativa} color='#000000' sx={{ fontWeight: "bold" }} >Informativa</Link>, acconsento al trattamento dei dati personali, all’eventuale comunicazione ai soggetti elencati nell’informativa per le finalità e con le modalità indicate e al trasferimento all’estero, anche in paesi non appartenenti all’Unione Europea.
                    </Typography>

                    <FormControlLabel
                        control={<Checkbox id="acceptTerms" name="acceptTerms" sx={{ m: 0, p: 0, ml: '-2px', pr: '.7rem', color: "#6369E9", borderBlockColor: "#707070", fontSize: '12px', }} required={true} />}
                        label={<Typography variant="body2">Accetto la privacy policy</Typography>}
                        sx={{
                            m: 0,
                            pt: '.7rem',
                            width: '100%',
                            fontSize: '12px',
                        }}
                    />

                    <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 2, p: "0 25%", textAlign: "center" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ p: '8px 50px', backgroundColor: '#2CA4DE' }}
                        >
                            Registrati
                        </Button>
                    </Stack>

                    </form>
            </Box>

        </Container>
    );
}

const styles = {
    inputText: {
        backgroundColor: '#EEF2F6',
        border: '0px solid',
        p: '1rem',
        borderRadius: '10px',
    },
}