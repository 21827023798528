import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// pages
import Logout from '../pages/Logout';

// layouts
import OnlyFormLayout from '../layouts/OnlyFormLayout';
import Dashboard from '../pages/main/Dashboard';
import AdminDashboard from '../pages/admin/Dashboard';
//import IndexPage from '../layouts/IndexPage';
//import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import OtpGuard from '../guards/OtpGuard';
import AdminGuard from '../guards/AdminGuard';
//åimport RoleBasedGuard from '../guards/RoleBasedGuard';

// Components
import ServiceList from '../components/admin/services/ServiceList';
import ServiceCreate from '../components/admin/services/ServiceCreate';
import ServiceUpdate from '../components/admin/services/ServiceUpdate';

import StringList from '../components/admin/strings/StringList';
import StringCreate from '../components/admin/strings/StringCreate';
import StringUpdate from '../components/admin/strings/StringUpdate';

import OtpTab from '../components/OtpTab';
import HomeLayout from '../layouts/HomeLayout';
import ServicesLayout from '../layouts/ServicesLayout';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import PrivacyLayout from '../layouts/PrivacyLayout';
import UserProfile from '../pages/main/UserProfile';

import LoginRegisterTabs from '../components/LoginRegisterTabs';
import RequestPasswordTab from '../components/RequestPasswordTab';
import ResetPasswordTab from '../components/ResetPasswordTab';
import MaterialiInformativiLayout from '../layouts/MaterialiInformativiLayout';
import ProgettoLayout from '../layouts/ProgettoLayout';

// Pages
import InformativaPrivacy from '../pages/privacy/InformativaPrivacy';
import TerminiUso from '../pages/privacy/TerminiUso';
import Cookies from '../pages/privacy/Cookies';

export default function Router() {
    return useRoutes([
      { path: '/', element: <Navigate to="/main/dashboard" replace /> },
      
      // Auth Routes
      {
        path: 'auth',
        element: (<OnlyFormLayout />),
        children: [
          { path: 'login', element: <GuestGuard><LoginRegisterTabs /></GuestGuard> },
          { path: 'password/request', element: <GuestGuard><RequestPasswordTab /></GuestGuard> },
          { path: 'password/reset/:otpCode', element: <GuestGuard><ResetPasswordTab /></GuestGuard> },

          { path: 'otp/verify', element: <OtpGuard><OtpTab /></OtpGuard> },

          { path: 'logout', element: <AuthGuard><Logout /></AuthGuard> },
        ],
      },
      // Main Routes
      {
        path: 'main',
        element: (
          <Dashboard />
        ),
        children: [
          { element: <Navigate to="/main/dashboard" replace /> },
          { path: 'dashboard', element: <HomeLayout /> },
          { path: 'services', element: <ServicesLayout /> },
          { path: 'materiali-informativi', element: <AuthGuard><MaterialiInformativiLayout /></AuthGuard> },
          { path: 'progetto', element: <ProgettoLayout /> },
          {
            path: 'user',
            children: [
              { element: <Navigate to="/main/user/profile" replace /> },
              { path: 'profile', element: <AuthGuard><UserProfile /></AuthGuard> },
              // { path: 'cards', element: <UserCards /> },
              // { path: 'list', element: <UserList /> },
              // { path: 'new', element: <UserCreate /> },
              // { path: ':name/edit', element: <UserCreate /> },
              // { path: 'account', element: <UserAccount /> }
            ]
          }
        ]
      },
      // Privacy Routes
      {
        path: 'privacy',
        element: (
          <PrivacyLayout />
        ),
        children: [
          // { element: <Navigate to="/main/dashboard" replace /> },
          { path: 'informativa', element: <InformativaPrivacy /> },
          { path: 'termini-uso', element: <TerminiUso /> },
          { path: 'cookies', element: <Cookies /> },
        ]
      },
  
      // Admin Routes
      {
        path: 'admin',
        element: (
          <AdminGuard>
            <DashboardLayout />
          </AdminGuard>
        ),
        children: [
          { element: <Navigate to="/admin/dashboard" replace /> },
          { path: 'dashboard', element: null },
          {
            path: 'services',
            children: [
              { element: <Navigate to="/admin/services" replace /> },
              { path: 'list', element: <ServiceList /> },
              { path: 'create', element: <ServiceCreate /> },
              { path: 'update/:serviceId', element: <ServiceUpdate /> },
            ]
          },
          {
            path: 'strings',
            children: [
              { element: <Navigate to="/admin/strings" replace /> },
              { path: 'list', element: <StringList /> },
              { path: 'create', element: <StringCreate /> },
              { path: 'update/:stringId', element: <StringUpdate /> },
            ]
          }
        ]
      }
    ]);
  }