import { useEffect } from 'react';

// routes
import { PATH_AUTH } from '../routes/paths';
// hooks
import useAuth from '../hooks/useAuth';

import { Box, Typography } from '@mui/material';


export default function Logout() {
    const { logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        try {
            await logout();
            
            window.location.href = PATH_AUTH.login;
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(async () => {
        handleLogout();
    }, []);

    return (
        <Box>
            <Typography variant="h4" color="text.primary">
                Logout
            </Typography>
        </Box>
    );

  }