// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// Utils
import axiosInstance from '../../../utils/axios';
// ServiceForm
import StringForm from './StringForm';

const stringForm = () => {
    const [string, setString] = useState({});
    const [alertError, setAlertError] = useState({});
    const [noString, setNoString] = useState(false);

    const { stringId } = useParams();

    // call backend to retrieve services data by its id
    const getString = async (id) => {
        await axiosInstance.post(`/strings/get/${id}`)
            .then(response => {
                setString(response.data.string);
            })
            .catch(error => {
                console.error(error)
                
                setNoString(true);
                setAlertError(error.data.message)
            });
    }

    const handleAlertError = (message) => {
        setAlertError(message);
    }

    useEffect(() => {
        if (stringId) {
            getString(stringId);
        }
    }, []);

    return (

        <Container component="div" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Update String
                </Typography>
            <>
                {
                    Object.keys(alertError).length !== 0 
                        ? <Alert 
                            severity="error"
                            sx={{m: 3}}
                        >
                            {alertError}
                        </Alert>
                        : null
                }
            </>

            {
                !noString
                    ? <StringForm string={string} handleError={handleAlertError} />
                    : null
            }

            </Box>
        </Container>
    );
}

export default stringForm;