import { Outlet } from 'react-router-dom';

import { Box } from "@mui/system";
// components
import FrontendAppMenu from "../components/FrontendAppMenu";
import Footer from '../components/Footer';
import ContactButton from '../components/ContactButton';
import CookieBar from '../components/CookieBar';
// hooks
import useAuth from '../hooks/useAuth';

export default function PrivacyLayout() {
  const { user } = useAuth();

  return (
    <Box 
      sx={{
        backgroundColor: '#F4F7F7',
        width: '100%',
        padding: '0px',
      }} 
    >
      <Box>
        <FrontendAppMenu />
        <Outlet />
        <ContactButton />
        <CookieBar />
        <Footer />
      </Box>
    </Box>
  );
}