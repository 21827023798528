import { useState, useEffect } from "react";
// @mui
import { Box } from "@mui/system";
import { Container, Link } from "@mui/material";
import { Typography, Button, Grid } from "@material-ui/core";
// @mui icons
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
// hooks
import useAuth from "../hooks/useAuth";
// utils
import axios from "../utils/axios";
// Slate
import escapeHtml from "escape-html";
import ReactHtmlParser from "react-html-parser";
import { Text } from "slate";

import ReactImageVideoLightbox from "react-image-video-lightbox";

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }
  const children = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "quote":
      return `<blockquote>${children}</blockquote>`;
    // return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `${children}`;
    // return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export default function ServicesBoxes(props) {
  const { isAuthenticated } = useAuth();

  const [services, setServices] = useState([]);
  const [externalUuid, setExternalUuid] = useState("");
  const [isOpen, setIsOpen] = useState([]);

  useEffect(() => {
    getAllServices();
    getUserExternalUuid();
  }, []);

  const getUserExternalUuid = () => {
    axios
      .post("/profile/details", {})
      .then((res) => {
        const externalUuid = res.data.external_uuid;
        setExternalUuid(externalUuid);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateSpecificServiceIsOpen = (index, status) => {
    let isOpenArr = [];
    isOpen.map((item, i) => {
      if (i === index) {
        isOpenArr.push(status);
      } else {
        isOpenArr.push(false);
      }
    });
    setIsOpen(isOpenArr);
  };

  const getAllServices = () => {
    const data = {
      limit: props?.items,
      orderBy: "desc",
    };

    axios
      .post("/services/list", data)
      .then((res) => {
        const allServices = res.data.services;
        setServices(allServices);

        // set isOpen to false for each service
        let isOpenArr = [];
        allServices.map(() => {
          isOpenArr.push(false);
        });
        setIsOpen(isOpenArr);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container
      maxWidth="false"
      sx={{
        pt: "40px",
        pb: "40px",
      }}
      style={props.style}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: {
            xs: "0 10px",
            md: "0 30px",
          },
        }}
      >
        <Grid container maxWidth="xl" spacing={2}>
          {services.map((service, index) => {
            return (
              <Grid
                key={service.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                sx={{ paddingBottom: { xs: "20px", md: "30px" } }}
              >
                {service.video_url &&
                  service.video_url !== "" &&
                  isOpen[index] && (
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 9999,
                      }}
                    >
                      <ReactImageVideoLightbox
                        data={[
                          {
                            url: service.video_url,
                            type: "video",
                            title: "Video",
                          },
                        ]}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={() =>
                          updateSpecificServiceIsOpen(index, false)
                        }
                      />
                    </Box>
                  )}
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    padding: "2rem",
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={
                      service.video_url &&
                      service.video_url !== "" && {
                        position: "relative",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top: 0,
                          bottom: 0,
                          borderRadius: "15px",
                          // background: "rgba(0,0,0,.15)"
                        },
                      }
                    }
                  >
                    <img
                      src={
                        process.env.NODE_ENV === "development"
                          ? `${process.env.REACT_APP_API_BASE_URL_DEV}${service.image}`
                          : `${process.env.REACT_APP_API_URL_PRO}${service.image}`
                      }
                      alt={service.image}
                      loading="lazy"
                      width="100%"
                    />
                    {service.video_url && service.video_url !== "" && (
                      <Box
                        sx={{
                          width: "60px",
                          height: "60px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50px",
                          backgroundColor: "#2CA4DE",
                          boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.45)",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => updateSpecificServiceIsOpen(index, true)}
                      >
                        <PlayArrowRoundedIcon
                          sx={{ width: "50px", height: "50px", color: "white" }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {ReactHtmlParser(
                      serialize({ children: JSON.parse(service.description) })
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mt: 5,
                      mb: 2,
                      fontWeight: "bold",
                      "& a": { color: "#494A4C" },
                    }}
                  >
                    <Link
                      href={
                        isAuthenticated
                          ? externalUuid
                            ? service.link + "#uuid=" + externalUuid
                            : service.link + "#uuid="
                          : "/auth/login"
                      }
                      target={isAuthenticated ? "_blank" : null}
                    >
                      Richiedi servizio
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
        {props.loadMore != false ? (
          <Button
            component={Link}
            href="/main/services"
            color="primary"
            variant="blueColored"
            sx={{
              borderRadius: "50px",
              marginTop: {
                xs: "50px",
                md: "100px",
              },
            }}
          >
            <Typography
              color="white"
              sx={{ fontSize: "14px", padding: "4px 13px" }}
            >
              Vedi tutti i servizi
            </Typography>
          </Button>
        ) : null}
      </Box>
    </Container>
  );
}
