import { Text } from "slate";
import escapeHtml from "escape-html";

export default function SlateSerializer(node) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.apex) {
      string = `<sup>${string}</sup>`;
    }
    if (node.link) {
      string = `<a>${string}</a>`;
    }
    return string;
  }
  const children = node.children.map((n) => SlateSerializer(n)).join("");

  switch (node.type) {
    case "quote":
      return `<blockquote>${children}</blockquote>`;
      // return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `${children}`;
      // return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "color":
      return `<span style="color: ${escapeHtml(node.colorPalette.color)};">${children}</span>`;
    case "apex":
      return `<sup>${children}</sup>`;
    case "breakline":
      return `<br />${children}`;
    default:
      return children;
  }
};

