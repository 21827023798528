import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import OtpForm from './OtpForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`loginregister-tabpanel-${index}`}
      aria-labelledby={`loginregister-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `loginregister-tab-${index}`,
    'aria-controls': `loginregister-tabpanel-${index}`,
  };
}

export default function OtpTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRegisterSuccess = () => {
    setValue(0);
  }

  return (
    <Box 
      sx={{ 
        width: "85%",
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        pt: 5,
        pb: 5,
        boxShadow: '0px 25px 80px rgba(4, 73, 144, 0.52)',
      }}
    >
      <Box sx={{ 
          display: `flex`,
          justifyContent: `center`,
          m: 0,
          p: 0,
          pt: 5,
          pb: 5,
       }}>
        <OtpForm />
      </Box>
    </Box>
  );
}