import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { useEffect } from 'react';
import axios from "./utils/axios";

import { BrowserRouter } from 'react-router-dom';

// analytics
import Analytics from 'analytics'
import { AnalyticsProvider, useAnalytics } from 'use-analytics'
import AnalyticsPlugin from './utils/AnalyticsPlugin';
import { onIdle, onWakeUp, onUserActivity } from '@analytics/activity-utils'

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: 'care-together',
  plugins: [
    AnalyticsPlugin({})
  ]
})

const recordSession = async () => {
  const data = {
    type: "session",
    resource: window.location.protocol + "//" + window.location.hostname,
    visitor_id: window.localStorage.getItem('__anon_id')
  }

  try {
    const response = await axios.post('/events/record', data);
  } catch (error) {
    console.error(error);
  }
}

const idleListener = onUserActivity({
  // timeout: FIVE_MIN,
  timeout: 20000,
  throttle: 10000,
  onIdle: async (activeTime, event) => {
    const record = await recordSession();
  },
  onWakeUp: async (idleTime, event) => {
    const record = await recordSession();
  },
  onHeartbeat: async (timeActive, event) => {
    const record = await recordSession();
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme} >
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    
    <AnalyticsProvider instance={analytics}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AnalyticsProvider>

  </ThemeProvider>,
  document.querySelector('#root'),
);
