import { useState, useEffect } from "react";

// hooks
import useAuth from "../../hooks/useAuth";
// utils
import axios from "../../utils/axios";
// components
import FrontendAppMenu from "../../components/FrontendAppMenu";
import PreFooter from "../../components/PreFooter";
import PageHeader from "../../components/PageHeader";
import RoundedBox from "../../components/RoundedBox";

import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";

export default function UserProfile(props) {
  const classes = { paper: "paper", root: "root fullscreen" };
  const { role } = useAuth();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ["prefooter_homepage_content"];
  // user historic
  const [firstUseDate, setFirstUseDate] = useState(null);
  const [expireAccessDate, setExpireAccessDate] = useState(null);

  const getStrings = async () => {
    try {
      const response = await axios.post("/strings/list/subset", {
        keys: stringKeys,
      });
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileHistoric = async () => {
    try {
      const response = await axios.post("/profile/historic", {});

      if (response.data.historic.firstUseDate) {
        setFirstUseDate(new Date(response.data.historic.firstUseDate));
      }

      if (response.data.historic.expireAccessDate) {
        setExpireAccessDate(new Date(response.data.historic.expireAccessDate));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileHistoric();
    getStrings();
  }, []);

  const getRemainingDays = (date2, date1) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY); // days
  };

  return (
    <>
      <Box sx={{ minHeight: "70vh" }}>
        <FrontendAppMenu />
        <PageHeader title="Profilo" />
        <Box sx={{ padding: "0 50px" }}>
          <Stack spacing={0}>
            <RoundedBox
              noHtmlDescription={
                firstUseDate !== null &&
                `Registrazione avvenuta ${firstUseDate.toLocaleDateString(
                  "it-IT",
                  options
                )}.`
              }
              sx={styles.roundedBoxStyles}
            />
            {role !== "ADMIN" && (
              <RoundedBox
                noHtmlDescription={
                  expireAccessDate !== null
                    ? `Hai a disposizione ancora ${getRemainingDays(
                        expireAccessDate,
                        new Date()
                      )} giorni di utilizzo.`
                    : "La durata di utilizzo dei servizi varia a seconda del tipo di assistenza richiesta:\n - I servizi di caregiving professionale (accompagnamento, commissione e compagnia) sono disponibili per 3 mesi \n - I servizi sanitari (fisioterapia e counseling nutrizionale) sono disponibili per 6 mesi"
                }
                sx={styles.roundedBoxStyles}
              />
            )}
          </Stack>
        </Box>
      </Box>
      <PreFooter content={strings["prefooter_homepage_content"]} />
    </>
  );
}

const styles = {
  roundedBoxStyles: {
    roundedBox: {
      paddingTop: {
        xs: "0px !important",
        sm: "0px !important",
        md: "0px !important",
      },
    },
  },
};
