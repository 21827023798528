import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import theme from "../theme";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

export default function PageHeader(props) {
  // const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Container
      maxWidth="false"
      sx={{
        pt: {
          xs: "70px",
          md: "100px",
          lg: "200px"
        },
        pb: {
          xs: "50px",
          md: "70px",
          lg: "100px"
        },
      }}
    >
      <Grid
        container
        maxWidth="xl"
        spacing={0}
        direction="row"
        margin="0 auto"
        alignItems="center"
        sx={{
          padding: "0 24px"
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Typography
              variant="h4"
              sx={{ 
                mt: "50px", 
                mb: "20px",
                fontWeight: "bold",
                color: "#2CA4DE",
                fontSize: {
                  xs: "30px",
                  sm: "35px",
                  md: "40px"
                }
              }}
            >
              {
                props.title
                ? props.title
                : ''
              }
            </Typography>

            <Typography
              variant="noSpaceParagraph"
              sx={{ 
                width: {
                  xs: "100%",
                  sm: "80%",
                  md: "65%",
                  lg: "50%"
                },
                mt: "20px",
                textAlign: "center"
              }}
            >
              {
                props.subtitle
                ? ReactHtmlParser(
                  SlateSerializer({ children: JSON.parse((props.subtitle)) })
                )
                : ''
              }
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
