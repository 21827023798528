import React, { useEffect, useMemo, useCallback } from 'react';

import { Button, Icon, Toolbar } from '@mui/material';
// icons
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
// slate editor
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement
} from 'slate'
import { withHistory } from 'slate-history';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
  'shift+enter': 'breakline',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const withInlines = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element =>
    ['link', 'color'].includes(element.type) || isInline(element)

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const SlateEditor = (props) => {
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), [])

    useEffect(() => {
        if (props.initialValue) {
            Transforms.removeNodes(editor, { at: [0] })
            Transforms.insertNodes(
                editor,
                JSON.parse(props.initialValue)
            )
        } else if (props.initialValue === '') {
            Transforms.insertNodes(
                editor,
                [{ type: 'paragraph', children: [{ text: '' }] }]
            )
        }
    }, [props.initialValue]);

    return(
        <Slate
            editor={editor}
            value={[]}
            onChange={value => {
                const isAstChange = editor.operations.some(
                    op => 'set_selection' !== op.type
                )
                if (isAstChange) {
                    props.handleChange(JSON.stringify(value));
                }
            }}
        >
            <Toolbar

            sx={{
                border: '1px solid rgb(188 188 188)', 
                borderRadius: '5px 5px 0px 0px', 
                padding: '10px'
            }}
            >
                <MarkButton format="bold" icon={<FormatBoldIcon />} />
                <MarkButton format="italic" icon={<FormatItalicIcon />} />
                <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
                <MarkButton format="code" icon={<CodeIcon />} />
                <MarkButton format="apex" icon={<SuperscriptIcon />} />
                <AddLinkButton />
                <RemoveLinkButton />
                <ToggleEditableColorButton />
                <BlockButton format="heading-one" icon={<LooksOneIcon />} />
                <BlockButton format="heading-two" icon={<LooksTwoIcon />} />
                <BlockButton format="block-quote" icon={<FormatQuoteIcon />} />
                <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
                <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
                <BlockButton format="left" icon={<FormatAlignLeftIcon />} />
                <BlockButton format="center" icon={<FormatAlignCenterIcon />} />
                <BlockButton format="right" icon={<FormatAlignRightIcon />} />
                <BlockButton format="justify" icon={<FormatAlignJustifyIcon />} />
            </Toolbar>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.placeholder}
                spellCheck
                autoFocus
                onKeyDown={event => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                            event.preventDefault()
                            const mark = HOTKEYS[hotkey]
                            if (mark === "breakline")
                              insertBreakline(editor)
                            else
                              toggleMark(editor, mark)
                        }
                    }
                }}
                style={{
                    border: '1px solid rgb(188 188 188)', 
                    borderTop: 'none', 
                    borderRadius: '0px 0px 5px 5px', 
                    padding: '10px'
                }}
            />
        </Slate>
    );
}

const insertBreakline = editor => {
  const breakline = {
    type: 'breakline',
    children: [{ text: "" }]
  }
  
  Transforms.insertNodes(editor, breakline)
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties= <Element></Element> // Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const isColorActive = editor => {
  const [color] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'color',
  })
  return !!color
}

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'breakline':
      return (
        <span>
          {children}
        </span>
      )
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      )
    case 'color':
      return (
        <span style={element.colorPalette} {...attributes}>
          {children}
        </span>
      )
    case 'apex':
      return (
        <sup style={style} {...attributes}>
          {children}
        </sup>
      )
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.apex) {
    children = <sup>{children}</sup>
  }

  if (leaf.link) {
    children = <a>{children}</a>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      ).toString()}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      sx={{p: 0}}
    >
      <Icon sx={{mb: 1}}>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format).toString()}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      sx={{p: 0}}
    >
      <Icon sx={{mb: 1}}>{icon}</Icon>
    </Button>
  )
}

const AddLinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor).toString()}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <InsertLinkIcon />
    </Button>
  )
}

const RemoveLinkButton = () => {
  const editor = useSlate()

  return (
    <Button
      active={isLinkActive(editor).toString()}
      onMouseDown={event => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <LinkOffIcon />
    </Button>
  )
}

const ToggleEditableColorButton = () => {
  const editor = useSlate()
  return (
    <Button
      active="false"
      onMouseDown={event => {
        event.preventDefault()
        if (isColorActive(editor)) {
          unwrapColor(editor)
        } else {
          const colorPalette = window.prompt('Enter color:')
          if (!colorPalette) return
          insertColor(editor, colorPalette)
        }
      }}
    >
      <ColorLensIcon />
    </Button>
  )
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    // Add https protocol to link in case they dont't have
    if (url.indexOf("http://") != 0 && url.indexOf("https://") != 0 && url.indexOf("mailto:") != 0 && url.indexOf("tel:") != 0) {
      url = "https://" + url;
    }
    wrapLink(editor, url)
  }
}

const insertColor = (editor, colorPalette) => {
  if (editor.selection) {
    if (colorPalette) {
      // Add jsx color styles
      colorPalette = {color: colorPalette};
    }
    wrapColor(editor, colorPalette)
  }
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && selection.isCollapsed
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const wrapColor = (editor, colorPalette) => {
  if (isColorActive(editor)) {
    unwrapColor(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && selection.isCollapsed
  const color = {
    type: 'color',
    colorPalette,
    children: isCollapsed ? [{ text: 'Edit me!' }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, color)
  } else {
    Transforms.wrapNodes(editor, color, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const unwrapColor = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'color',
  })
}

export default SlateEditor;