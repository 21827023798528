import axios from "./axios";

export default function AnalyticsPlugin(userConfig) {
    // return object for analytics to use
    return {
        name: 'analytics-plugin-care-together',
        config: {
            // code here
        },
        initialize: ({ config }) => {
            // load provider script to page
        },
        page: async ({ payload }) => {
            // call provider specific page tracking
            const data = {
                type: "pageview",
                resource: payload.properties.url,
                visitor_id: payload.anonymousId
            };

            try {
                const response = await axios.post('/events/record', data);
            } catch (error) {
                console.error(error);
            }
        },
        track: ({ payload }) => {
            // call provider specific event tracking
        },
        identify: ({ payload }) => {
            // call provider specific user identify method
        },
        // loaded: () => {
        //     // return boolean so analytics knows when it can send data to third party
        //     return !!window.myPluginLoaded
        // }
    }
}