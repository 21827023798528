import axios from 'axios';
import jwtDecode from 'jwt-decode';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRO,
  headers: {
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : null
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if ( localStorage.getItem("accessToken") ) {
        const decodedJwt = jwtDecode(localStorage.getItem("accessToken"));
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        delete axiosInstance.defaults.headers.Authorization;

        if (decodedJwt.exp * 1000 < Date.now()) {
          window.location.href = '/auth/login';
          // window.history.pushState({}, 'login', '/auth/login');
        }
      }

      return Promise.reject(
        error.response || {
          status: 401,
          data: {
            status: 'fail',
            message: 'Unauthorized'
          }
        }
      );
    }

    console.error(error.response.status);

    return Promise.reject(
      error.response || {
        status: 500,
        data: {
          status: 'fail',
          message: 'Something went wrong'
        }
      }
    );
  }
);

export default axiosInstance;