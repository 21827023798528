import * as React from "react";

import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// hooks
import useAuth from "../hooks/useAuth";
// paths
import { PATH_MAIN, PATH_AUTH } from "../routes/paths";

const ResponsiveAppBar = () => {
  const { isAuthenticated } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  var pages = [
    { id: 1, name: "Servizi di supporto", link: PATH_MAIN.main.services },
    {
      id: 2,
      name: "Materiali informativi",
      link: PATH_MAIN.main.materialiInformativi,
    },
    { id: 3, name: "Il progetto", link: PATH_MAIN.main.progetto },
    { id: 4, name: "Profilo", link: PATH_MAIN.user.profile },
  ];

  if (!isAuthenticated) {
    var pages = [
      { id: 1, name: "Servizi di supporto", link: PATH_MAIN.main.services },
      { id: 3, name: "Il progetto", link: PATH_MAIN.main.progetto },
    ];
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const windowRedirect = (url) => {
    window.location.href = url;
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        background: "transparent",
        color: "primary.main",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", lg: "flex" } }}
          >
            <Link to={PATH_MAIN.main.dashboard} style={styles.logoLink}>
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                }}
                src="/static/images/cart-logo.svg"
                alt="logo-car-t"
              />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.link}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
          >
            <Link to={PATH_MAIN.main.dashboard} style={styles.logoLink}>
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                }}
                src="/static/images/cart-logo.svg"
                alt="logo-car-t"
              />
            </Link>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                lg: "flex",
              },
              paddingLeft: {
                lg: "75px",
              },
              justifyContent: {
                md: "center",
                lg: "unset",
              },
            }}
          >
            {pages.map((page) => (
              <Button
                component={Link}
                to={page.link}
                key={page.id}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#004990",
                  display: "block",
                  fontWeight: "bold",
                  padding: {
                    xs: "1rem",
                    md: "1.55rem",
                  },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              flexGrow: 0,
              paddingRight: {
                lg: "75px",
              },
            }}
          >
            <Button
              color="inherit"
              variant="blueColored"
              sx={{
                borderRadius: "50px",
              }}
              onClick={() => {
                if (isAuthenticated) {
                  openInNewTab("mailto:info@caretogether.it");
                  return;
                }

                windowRedirect("/auth/login");
              }}
            >
              <Typography
                color="white"
                sx={{ fontSize: "14px", padding: "4px 13px" }}
              >
                {isAuthenticated ? "Assistenza" : "Accedi"}
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;

const styles = {
  logoLink: {
    lineHeight: "0",
  },
};
