import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box, ThemeProvider } from "@mui/system";
import { Button } from "@mui/material";
// utils
import axios from "../utils/axios";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";
import { PATH_MAIN } from "../routes/paths";

export default function HomeBanner(props) {
  const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Container
      maxWidth="false"
      sx={{
        backgroundImage: "url(/static/images/banner-bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        pt: {
          xs: "70px",
          md: "100px",
          lg: "200px"
        },
        pb: {
          xs: "70px",
          md: "100px",
          lg: "200px"
        },
      }}
    >
      <Grid
        container
        maxWidth="xl"
        spacing={0}
        direction="row"
        margin="0 auto"
        alignItems="end"
        sx={{
          padding: "0 24px"
        }}
      >
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <Box
              component="img"
              sx={{
                  maxWidth: {
                      xs: "70%",
                      md: "100%"
                  }
              }}
              src="/static/images/logo-cart.svg" alt="logo-car-t" 
          />
          <Box>
            <Typography
              component="p"
              variant="banner"
              color="textSecondary"
              sx={{ mt: "50px", mb: "20px" }}
            >
              {
                props.description
                ? ReactHtmlParser(
                    SlateSerializer({ children: JSON.parse((props.description)) })
                  )
                : ''
              }
            </Typography>

            <Button
              component={Link}
              to={PATH_MAIN.main.progetto}
              fullWidth
              variant="whiteBlueColored"
              sx={{
                mt: 3,
                mb: 2,
                width: "auto",
              }}
            >
              Il progetto
            </Button>

            <Button
              component={Link}
              to={PATH_MAIN.main.services}
              fullWidth
              variant="redColored"
              sx={{
                mt: 3,
                mb: 2,
                ml: 3,
                width: "auto",
              }}
            >
              Richiedi un servizio
            </Button>
          </Box>
        </Grid>
        <Grid item xs={false} sm={1} md={1} lg={1} />
        <Grid item xs={12} sm={6} md={6} lg={6} textAlign="center">
          <Box
            component="img"
            sx={{
              maxWidth: "100%",
            }}
            src="/static/images/banner-front-img.svg"
            alt="logo-car-t"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
