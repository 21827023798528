// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
  }
  
  const ROOTS_AUTH = '/auth';
  const ROOTS_MAIN = '/main';
  const ROOTS_PRIVACY = '/privacy';
  const ROOTS_ADMIN = '/admin';
  
  // ----------------------------------------------------------------------
  
  export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    logout: path(ROOTS_AUTH, '/logout'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    resetPasswordConfirm: path(ROOTS_AUTH, '/reset-password-confirm'),
    verify: path(ROOTS_AUTH, '/verify')
  };
  
  export const PATH_PAGE = {
    maintenance: '/maintenance',
    page404: '/404',
    page500: '/500'
  };
  
  export const PATH_MAIN = {
    root: ROOTS_MAIN,
    main: {
      dashboard: path(ROOTS_MAIN, '/dashboard'),
      services: path(ROOTS_MAIN, '/services'),
      materialiInformativi: path(ROOTS_MAIN, '/materiali-informativi'),
      progetto: path(ROOTS_MAIN, '/progetto')
    },
    user: {
      root: path(ROOTS_MAIN, '/user'),
      profile: path(ROOTS_MAIN, '/user/profile'),
      list: path(ROOTS_MAIN, '/user/list'),
      account: path(ROOTS_MAIN, '/user/account')
    },
    fax: {
      quickfax: path(ROOTS_MAIN, '/fax/quickfax/form'),
      quickfaxupload: path(ROOTS_MAIN, '/fax/quickfax/upload'),
      quickfaxpreview: path(ROOTS_MAIN, '/fax/quickfax/preview')
    },
    report: {
      root: path(ROOTS_MAIN, '/report'),
      faxout: path(ROOTS_MAIN, '/report/faxout'),
      faxin: path(ROOTS_MAIN, '/report/faxin'),
      faxready: path(ROOTS_MAIN, '/report/faxready'),
      faxdetails: path(ROOTS_MAIN, '/report/faxdetails')
    },
    payments: {
      root: path(ROOTS_MAIN, '/payments/nexi-result')
    },
    billing: {
      plan: path(ROOTS_MAIN, '/billing/plan'),
      transactions: path(ROOTS_MAIN, '/billing/transactions')
    }
  };

  export const PATH_PRIVACY = {
    root: ROOTS_PRIVACY,
    main: {
      informativa: path(ROOTS_PRIVACY, '/informativa'),
      terminiUso: path(ROOTS_PRIVACY, '/termini-uso'),
      cookies: path(ROOTS_PRIVACY, '/cookies')
    }
  };
  
  export const PATH_ADMIN = {
    root: ROOTS_ADMIN,
    admin: {
      dashboard: path(ROOTS_ADMIN, '/dashboard')
    },
    service: {
      root: path(ROOTS_ADMIN, '/services'),
      list: path(ROOTS_ADMIN, '/services/list'),
      create: path(ROOTS_ADMIN, '/services/create'),
      update: path(ROOTS_ADMIN, '/services/update'),
    },
    user: {
      list: path(ROOTS_ADMIN, '/user/list'),
      edit: path(ROOTS_ADMIN, '/user/edit'),
      new: path(ROOTS_ADMIN, '/user/new')
    },
    billing: {
      invoice_list: path(ROOTS_ADMIN, '/billing/invoice/list'),
      movement_list: path(ROOTS_ADMIN, '/billing/movement/list'),
      plan_list: path(ROOTS_ADMIN, '/billing/plan/list')
    }
  };